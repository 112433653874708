<template>
  <p></p>
  <div class="container-fluid">
    <div class="row">
      <div class="col">{{ $t('reports.reportstransactions.beginningOfPeriod') }}</div>
      <div class="col">{{ $t('reports.reportstransactions.endOfPeriod') }}</div>
      <div class="col">{{ $t('reports.reportstransactions.timezone') }}</div>
      <div class="col">{{ $t('reports.reportstransactions.type') }}</div>
      <div class="col">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <el-config-provider :locale="langEl">
          <el-date-picker
              v-model="userfilterData.ds"
              type="datetime"
              :format="dataformatEl"
          />
        </el-config-provider>
      </div>
      <div class="col">
        <el-config-provider :locale="langEl">
          <el-date-picker
              v-model="userfilterData.de"
              type="datetime"
              :format="dataformatEl"
          />
        </el-config-provider>
      </div>
      <div class="col">
        <el-select v-model="userfilterData.tz" filterable placeholder="Select">
          <el-option
              v-for="item in tz_list"
              :key="item.id"
              :label="item.text"
              :value="item.id"
          />
        </el-select>
      </div>
      <div class="col">
        <Select2 v-model="filter_res" :options="trans_type" :settings="{ width: '100%' }" />
      </div>
      <div class="col">
        <button @click="runReport" class="btn btn-success m-1"><span v-if="!is_run">{{ $t('reports.reportstransactions.build') }}</span><span v-else>{{ $t('reports.reportstransactions.update') }}</span></button>
        <!--button v-if="rows.length>0" @click="downloadFile()" class="btn btn-primary m-1">{{ $t('reports.reportstransactions.export') }}</button-->
      </div>
    </div>
  </div>
  <p></p>
  <vue-good-table v-if="rows.length>0"
    styleClass="vgt-table nowrap bordered"
    :columns="columns"
    :rows="rows"
    :pagination-options="{
      enabled: true,
      perPage: 100,
      perPageDropdown: perPageDropdown,
      nextLabel: $t('app.vue-good-table-next.nextLabel'),
      prevLabel: $t('app.vue-good-table-next.prevLabel'),
      rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
      ofLabel: $t('app.vue-good-table-next.ofLabel'),
      pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
      allLabel: $t('app.vue-good-table-next.allLabel')
    }"
    :sort-options="{
      enabled: true,
      initialSortBy: {field: 'id', type: 'asc'}
    }"
    :row-style-class="rowStyleClassFn"
    compactMode>
    <template #emptystate>
      <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
    </template>
  </vue-good-table>
  <div class="alert alert-info" v-if="rows.length<1">{{ $t('reports.reportstransactions.dataNotFound') }}</div>
</template>

<script setup>
import {inject, computed, ref, onActivated, provide, watch} from 'vue'
import {useStore} from "vuex";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import ReportService from "../../services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";

const sortByInt = inject('sortByInt')

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const date_to_format = inject('date_to_format')

const $t = inject('$t')
const $i18n = inject('$i18n')

const store = useStore()

const set_result = inject('set_result')

const setIsLoading = inject('setIsLoading')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

const verifyDemoBill=inject('verifyDemoBill')

const updRep = inject('updRep')

function filtersFn()
{
  let res=[]

  res.push({type: 'datestart'})
  res.push({type: 'dateend'})
  res.push({type: 'timezone'})

  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const updateTabActive = ref(false)
const userfilterData = inject('filterData')
const updatefilterData = inject('updatefilterData')
//const downloadFileExcel = inject('downloadFileExcel')

const tz_list = inject('tz_list')

const SelOneBill = inject('SelOneBill')

const is_run = ref(false)
provide('is_run', is_run)

const date_start = ref('')
const date_end = ref('')
const value = ref('')
const filter_res = ref('all')
const tz_res = ref('UTC+0')
const trans_type = computed( () => [{"id": "all", "text": $t('reports.reportstransactions.all')},
  {"id": "refill", "text": $t('reports.reportstransactions.replenishment')},
  {"id": "write_off", "text": $t('reports.reportstransactions.writeoff')}
])

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
        {
          label: $t('reports.reportstransactions.transactionNumber'),
          field: 'id',
          tdClass: colStyleClassFn,
          sortFn: sortByInt,
        },
        {
          label: $t('reports.reportstransactions.replenishment'),
          field: 'money_size_refill',
          tdClass: colStyleClassFn,
        },
        {
          label: $t('reports.reportstransactions.decommissioning'),
          field: 'money_size_write_off',
          filterOptions: {enabled: true, placeholder: $t('reports.reportstransactions.decommissioning')},
          tdClass: colStyleClassFn,
        },
        {
          label: $t('reports.reportstransactions.accountSource'),
          field: 'source_bill',
          tdClass: colStyleClassFn,
        },
        {
          label: $t('reports.reportstransactions.accountRecipient'),
          field: 'destination_bill',
          filterOptions: {enabled: true, placeholder: $t('reports.reportstransactions.accountRecipient')},
          tdClass: colStyleClassFn,
        },
        {
          label: $t('reports.reportstransactions.login'),
          field: 'user_login',
          filterOptions: {enabled: true, placeholder: $t('reports.reportstransactions.login')},
          tdClass: colStyleClassFn,
        },
        {
          label: $t('reports.reportstransactions.date'),
          field: 'event_date',
          filterOptions: {enabled: true, placeholder: $t('reports.reportstransactions.date')},
          tdClass: colStyleClassFn,
        },
        {
          label: $t('reports.reportstransactions.comment'),
          field: 'user_comment',
          filterOptions: {enabled: true, placeholder: $t('reports.reportstransactions.comment')},
          tdClass: colStyleClassFn,
        }
      ])

const rows = ref([])

function rowStyleClassFn(row)
{
  return 'color_class_'+row.type_transactions
}

function colStyleClassFn(row)
{
  return 'color_class_'+row.type_transactions
}

function runReport()
{
  verifyDemoBill()

  setIsLoading(true)

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
      bills.push(cur)
  }

  rows.value=[]
  ReportService.getTransactions(data_type.value, bills, dstart, dend, userfilterData.tz, filter_res.value).then(
      (response) => {
          rows.value=response.data
          is_run.value=true
        setIsLoading(false)
        runProcess.value=true
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function setPeriod(date)
{
  updatefilterData({"ds": date.date_s, "de": date.date_e})
}
provide('setPeriod', setPeriod)

function downloadFile()
{
  //downloadFileExcel(rows.value, columns, 'details')
}

function selCurrencyWatch()
{
  runReport()
}
watch(updRep, selCurrencyWatch)


const i18nlocale = computed(() => $i18n.locale)

function onChangeLang()
{
  runReport()
}
watch(i18nlocale, onChangeLang)

function SelOneBillFn()
{
  if(SelOneBill.value!=='') {
    runReport()
  }
}

watch(SelOneBill, SelOneBillFn)

onActivated(() => {
  let date_start=ref(new Date());
  let cday=new Date();
  let date_end=ref(new Date(cday.setDate(cday.getDate()+1)))

  userfilterData.ds = new Date(date_start.value.toDateString())
  userfilterData.de = new Date(date_end.value.toDateString())

  runReport()
})
</script>

<style>
.link {
  cursor: pointer;
  color: blue;
}

.color_class_not_played {
  color: black !important;
  font-weight: bold;
}

.color_class_win_paid {
  color: darkgreen !important;
  font-weight: bold;
}

.color_class_win_not_paid {
  color: lightgreen !important;
}

.color_class_lost {
  color: gray !important;
}

.color_class_rejected {
  color: red !important;
}

.color_class_bill_refill {
  background-color: #dff0d8;
  color: #111111 !important;
}

.color_class_bill_withdraw {
  background-color: #f2dede;
  color: #111111 !important;
}
</style>