<template>
  <div v-if="rowsmain.length>0" class="row">
    <div class="col">
      <p>{{ $t('bill.billblock.youWillUnlock') }}<b>{{rowsmain[0]['bill_name']}} ({{rowsmain[0]['bill_id']}})</b></p>
    </div>
  </div>

  <div v-if="rowsparent.length>0" class="row">
    <div class="col">
      <p></p>
      <p><span>{{ $t('bill.billblock.toUnblockThis') }}</span>
        <span v-for="row in rowsparent" :key="row.bill_id">{{row['bill_name']}} ({{row['bill_id']}})</span></p>

      <button v-if="rowschildren.length==0" class="btn btn-success m-2" @click="BillBlocks('unblocked')">{{ $t('bill.billblock.yes') }}</button>
      <button v-if="rowschildren.length>0" class="btn btn-success m-2" @click="sub=true">{{ $t('bill.billblock.yes') }}</button>
      <button class="btn btn-danger m-2" @click="closeEdit">{{ $t('bill.billblock.no') }}</button>
    </div>
  </div>

  <div v-if="sub || rowsparent.length==0" class="row">
    <div class="col">
      <p></p>
      <div v-if="rowschildren.length>0">
        <p>{{ $t('bill.billblock.doYouWant') }}</p>
        <vue-good-table
            :columns="columns"
            :rows="rowschildren"
            :pagination-options="{
              enabled: true,
              perPage: 100,
              perPageDropdown: perPageDropdown,
              perPageDropdown: perPageDropdown,
              nextLabel: $t('app.vue-good-table-next.nextLabel'),
              prevLabel: $t('app.vue-good-table-next.prevLabel'),
              rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
              ofLabel: $t('app.vue-good-table-next.ofLabel'),
              pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
              allLabel: $t('app.vue-good-table-next.allLabel')
            }"
            compactMode>
          <template #emptystate>
            <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
          </template>
        </vue-good-table>
      </div>

      <button class="btn btn-success m-2" @click="BillBlocks('unblocked')">{{ $t('bill.billblock.unblockCurrentBill') }}</button>
      <button v-if="rowschildren.length>0" class="btn btn-success m-2" @click="BillBlocks('unblockedWithChild')">{{ $t('bill.billblock.unblockCurrentBill1') }}</button>
      <button @click="closeEdit" type="button" class="btn btn-danger" :title="$t('bill.bill.cancel')">{{ $t('bill.bill.cancel') }}</button>
    </div>
  </div>

</template>

<script setup>
import {inject, defineEmits, onMounted, ref, computed, defineProps} from "vue";
import BillService from "../../services/bill.service";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'

const $t = inject('$t')

const set_result = inject('set_result')

const emit = defineEmits(['selRow', 'onGetRows'])

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
  {
    label: $t('bill.bills.number'),
    field: 'bill_id',
  },
  {
    label: $t('bill.bills.name'),
    field: 'bill_name'
  }
])

const rowsmain = ref([])
const rowsparent = ref([])
const rowschildren = ref([])

const sub = ref(false)

const props = defineProps({
  selRow: Object,
  next_type: String
});

const rowSetActiveStatus = inject('rowSetActiveStatus')

const updBills = inject('updBills')

function getBillForBlocked()
{
  BillService.getBillsForBlocked(props.selRow.id).then(
      (response) => {
        for (let key in response.data) {
          if(response.data[key]['type']=='main') {
            rowsmain.value.push(response.data[key]);
          }

          if(response.data[key]['type']=='parent') {
            rowsparent.value.push(response.data[key]);
          }

          if(response.data[key]['type']=='children') {
            rowschildren.value.push(response.data[key]);
          }
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function closeEdit ()
{
  let param={is_sel: false}

  if(props.next_type=='edit_save') {
    param['type']=props.next_type
    param['sel_bill']=props.selRow
  }

  emit('selRow', param)
}

function BillBlocks(type = '')
{
  let param={is_edit: false}

  if(rowsmain.value[0]!==undefined) {
    rowSetActiveStatus(rowsmain.value[0]['bill_id'], type)
  }

  if(props.next_type=='edit_save') {
    param['type']=props.next_type
    param['sel_bill']=props.selRow
  }

  emit('onGetRows')
  emit('selRow', param)
  updBills()
}

function mounted_fn()
{
  getBillForBlocked()
}

onMounted(mounted_fn)
</script>
