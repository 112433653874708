<template>
  <button @click="setPeriod('today')" class="btn btn-light p-1 m-1">{{ $t('reports.reportshelpers.today') }}</button>
  <button @click="setPeriod('yesterday')" class="btn btn-light p-1 m-1">{{ $t('reports.reportshelpers.yesterday') }}</button>
  <button @click="setPeriod('last7day')" class="btn btn-light p-1 m-1">{{ $t('reports.reportshelpers.lastSevenDays') }}</button>
  <button @click="setPeriod('last30day')" class="btn btn-light p-1 m-1">{{ $t('reports.reportshelpers.lastThirtyDays') }}</button>
  <button @click="setPeriod('this_month')" class="btn btn-light p-1 m-1">{{ $t('reports.reportshelpers.thisMonth') }}</button>
  <button @click="setPeriod('last_month')" class="btn btn-light p-1 m-1">{{ $t('reports.reportshelpers.lastMonth') }}</button>
  <button @click="setPeriod('all')" class="btn btn-light" p-1 m-1>{{ $t('reports.reportshelpers.theEntirePeriod') }}</button>
</template>

<script setup>
import {inject, ref} from "vue";

const onSetPeriod = inject('setPeriod')

function setPeriod(type_set)
{
  let curday=new Date()
  let nextday=ref(new Date(curday.setDate(curday.getDate()+1)))

  let date_s=curday
  let date_e=nextday

  switch (type_set) {
    case 'today':
      curday=new Date()
      date_s=ref(new Date(curday.setDate(curday.getDate())))
      date_e=ref(new Date(curday.setDate(curday.getDate()+1)))

      if(curday.getHours()<6) {
        date_s.value=new Date(date_s.value.setDate(date_s.value.getDate()-1))
        date_e.value=new Date(date_e.value.setDate(date_e.value.getDate()-1))
      }

      date_s.value=new Date(date_s.value.setHours(6))
      date_s.value=new Date(date_s.value.setMinutes(0))
      date_s.value=new Date(date_s.value.setSeconds(0))
      date_s.value=new Date(date_s.value.setMilliseconds(0))

      date_e.value=new Date(date_e.value.setHours(6))
      date_e.value=new Date(date_e.value.setMinutes(0))
      date_e.value=new Date(date_e.value.setSeconds(0))
      date_e.value=new Date(date_e.value.setMilliseconds(0))
      break
    case 'yesterday':
      curday=new Date()
      date_s=ref(new Date(curday.setDate(curday.getDate()-1)))
      curday=new Date()
      date_e=ref(new Date(curday.setDate(curday.getDate())))

      if(curday.getHours()<6) {
        date_s.value=new Date(date_s.value.setDate(date_s.value.getDate()-1))
        date_e.value=new Date(date_e.value.setDate(date_e.value.getDate()-1))
      }

      date_s.value=new Date(date_s.value.setHours(6))
      date_s.value=new Date(date_s.value.setMinutes(0))
      date_s.value=new Date(date_s.value.setSeconds(0))
      date_s.value=new Date(date_s.value.setMilliseconds(0))

      date_e.value=new Date(date_e.value.setHours(6))
      date_e.value=new Date(date_e.value.setMinutes(0))
      date_e.value=new Date(date_e.value.setSeconds(0))
      date_e.value=new Date(date_e.value.setMilliseconds(0))
      break
    case 'last7day':
      curday=new Date()
      date_s=ref(new Date(curday.setDate(curday.getDate()-6)))
      date_s.value=date_s.value.toDateString()
      date_e.value=date_e.value.toDateString()
      break
    case 'last30day':
      curday=new Date()
      date_s=ref(new Date(curday.setDate(curday.getDate()-29)))
      date_s.value=date_s.value.toDateString()
      date_e.value=date_e.value.toDateString()
      break
    case 'this_month':
      curday=new Date()
      date_s=ref(new Date(curday.setDate(1)))
      date_s.value=date_s.value.toDateString()
      date_e.value=date_e.value.toDateString()
      break
    case 'last_month':
      curday=new Date()
      curday.setMonth(curday.getMonth()-1)
      date_s=ref(new Date(curday.setDate(1)))
      curday=new Date()
      date_e=ref(new Date(curday.setDate(1)))
      date_s.value=date_s.value.toDateString()
      date_e.value=date_e.value.toDateString()
      break
    case 'all':
      curday=new Date()
      curday.setMonth(0)
      curday.setFullYear(2022)
      date_s=ref(new Date(curday.setDate(1)))
      date_s.value=date_s.value.toDateString()
      date_e.value=date_e.value.toDateString()
      break
  }

  onSetPeriod({"date_s": new Date(date_s.value), "date_e": new Date(date_e.value)})
}
</script>
