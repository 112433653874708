<template>
  <div v-if="cntBill">
    <p></p>
    <p>{{ $t('billmanager.billmanagerbalans.account') }} <b>{{ content.name }} ({{ content.id }})</b>{{ $t('billmanager.billmanagerbalans.balance') }}<b>{{ content.balance }} {{ content.currency }}</b></p>
    <br>
    <button v-if="!is_change" @click="updBills" class="btn btn-sm btn-info m-1">{{ $t('billmanager.billmanagerbalans.refresh') }}</button>
    <span v-if="!is_get_data && (content.data_type==currentUser.data_type || currentUser.bill_id==1)">
      <button v-if="!isMainBill && content.status!='deleted' && !is_change" @click="balanseAdd" class="btn btn-sm btn-success m-1">{{ $t('billmanager.billmanagerbalans.topUp') }}</button>
      <button v-if="!isMainBill && !is_change" @click="balanseMinus" class="btn btn-sm btn-danger m-1">{{ $t('billmanager.billmanagerbalans.writeOff') }}</button>
    </span>
    <div v-if="is_change && !isMainBill">
      <p></p>
      <p v-if="is_add && currentUser.bill_id!=1">{{ $t('billmanager.billmanagerbalans.itIsPossible') }} <b>{{ mainBill.balance_ch }} {{ content.currency }}</b></p>
      <p v-if="is_minus && currentUser.bill_id!=1">{{ $t('billmanager.billmanagerbalans.canBeDebited') }} <b>{{ content.balance }} {{ content.currency }}</b></p>
      <div class="row p-2">
        <div class="col-1">{{ $t('billmanager.billmanagerbalans.sum') }}</div>
        <div class="col">
          <input type="text" v-model="updSum">
        </div>
      </div>
      <div class="row p-2">
        <div class="col-1">{{ $t('billmanager.billmanagerbalans.comment') }}</div>
        <div class="col">
          <textarea v-model="user_comment"></textarea>
        </div>
      </div>
      <button v-if="is_add" @click="changeBalanse" :disabled="disBut" class="btn btn-sm btn-primary m-1">{{ $t('billmanager.billmanagerbalans.topUp1') }}</button>
      <button v-if="is_minus" @click="changeBalanse" :disabled="disBut" class="btn btn-sm btn-primary m-1">{{ $t('billmanager.billmanagerbalans.writeOff1') }}</button>
      <button v-if="is_add || is_minus" @click="cancelChangeBalanse" class="btn btn-sm btn-secondary m-1">{{ $t('billmanager.billmanagerbalans.cancel') }}</button>
    </div>
    <hr>
    <ReportsTransactions></ReportsTransactions>
  </div>
</template>

<script setup>
import BillService from "../../services/bill.service";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {ref, inject, onMounted, computed, watch, provide, reactive} from "vue";
import {useStore} from "vuex";
import ReportsTransactions from "../Reports/ReportsTransactions.vue";

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const store = useStore()

const set_result = inject('set_result')

const isMainBill = inject('isMainBill')

const currentUser = inject('currentUser')

const disBut = ref(false)

const content = ref([])
const mainBill = ref([])
const is_sel = ref(false)
const sel_bill = ref([])
const is_change = ref(false)
const is_get_data = ref(false)
const is_add = ref(false)
const is_minus = ref(false)
const type = ref('')
const updSum = ref('')
const user_comment = ref('')
const maxupdSum = ref('')
const updRep = ref(0)
provide('updRep', updRep)

let date_start=ref(new Date());
let cday=new Date();
let date_end=ref(new Date(cday.setDate(cday.getDate()+1)))

const currentUserTZ = computed( ()=>currentUser.value.timezone)

const save_balance = ref('')

const filterData = reactive({
  ds: date_start,
  de: date_end,
  tz: 'UTC+0',
  chn: ''
})
provide('filterData', filterData)

function balanseAdd()
{
  is_change.value=true
  is_add.value=true
  is_minus.value=false
  maxupdSum.value=mainBill.value.balance
  getMain()
}

function balanseMinus()
{
  is_change.value=true
  is_minus.value=true
  is_add.value=false
  maxupdSum.value=parseFloat(content.value.balance.replaceAll(' ', ''))
}

function getSelect(selBill)
{
  is_get_data.value=true;

  if(selBill!=undefined) {
    sel_bill.value=selBill
    BillService.getBill(selBill).then(
      (response) => {
        content.value = response.data;

        is_get_data.value=false;
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function getMain()
{
  is_get_data.value=true;

  BillService.getMainBill().then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        mainBill.value = response.data
        maxupdSum.value = mainBill.value.balance

        is_get_data.value=false;
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
}

function updBills()
{
  let selBills=store.state.bill.selBills
  getSelect(selBills[0])
}

function changeBalanse()
{
  disBut.value = true;

  if(updSum.value=='' || parseFloat(updSum.value)==0) {
    set_result('406', null, $t('billmanager.billmanagerbalans.notinput'))
    return
  }

  let src_bill
  let dst_bill
  let type_transactions

  if(is_add.value) {
    src_bill=mainBill.value.id
    dst_bill=sel_bill.value
    type_transactions='bill_refill'
  }

  if(is_minus.value) {
    src_bill=sel_bill.value
    dst_bill=mainBill.value.id
    type_transactions='bill_withdraw'
  }

  save_balance.value = 'false'

  if(src_bill!==undefined) {
    BillService.changeBalanse(src_bill, dst_bill, updSum.value, type_transactions, user_comment.value, save_balance.value).then(
        () => {
          is_change.value=false
          updBills()
          getMain()
          updSum.value=''
          updRep.value++

          disBut.value = false;
        },
        (error) => {
          disBut.value = false;

          set_result(error.response.status, error)
        }
    )
  }
}

function cancelChangeBalanse()
{
  is_add.value=false
  is_minus.value=false
  is_change.value=false
  updSum.value=''
}

function updatefilterData (data)
{
  if(data.ds!=undefined) {
    filterData.ds = data.ds
  }
  if(data.de!=undefined) {
    filterData.de = data.de
  }
  if(data.tz!=undefined) {
    filterData.tz = data.tz
  }
  if(data.chn!=undefined) {
    filterData.chn = data.chn
  }
}
provide('updatefilterData', updatefilterData)

function updSumFn(newQuestion, oldQuestion)
{
  let max_val=parseFloat(maxupdSum.value)
  let new_val=newQuestion.replaceAll(',', '.')
  let clear_val=new_val.replaceAll(/[^0-9.]/g, '')
  if (clear_val!=new_val || new_val.split('.').length>2) {
    updSum.value = oldQuestion
  } else {
    clear_val=parseFloat(clear_val)
    let is_ver_sum=true
    if(currentUser.value.bill_id==1 && is_add.value) {
      is_ver_sum=false
    }

    if (clear_val > max_val && is_ver_sum) {
      updSum.value = oldQuestion
    }
  }
}
watch(updSum, updSumFn)

function cntBillFn()
{
  let selBills=store.state.bill.selBills
  let cnt = selBills.length

  if(cnt==1)
  {
    getSelect(selBills[0])
  }

  return cnt
}
const cntBill = computed(cntBillFn)

function is_superFn()
{
  let res_acces=false;
  if (currentUser.value && currentUser.value['roles']) {

    if(currentUser.value['roles'].includes('super')) {
      res_acces=true
    }
  }

  return res_acces
}
const is_super = computed(is_superFn)

onMounted(() => {
  updatefilterData({tz: currentUserTZ.value})
  getMain()
})
</script>
