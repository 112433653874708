import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'reports/';

class ReportService {
  getDetailed(data_type, bills, date_start, date_end, tz, status) {
    return axios.post(API_URLA + 'getDetailed/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz, "status": status});
  }
  getOverview(data_type, bills, date_start, date_end, tz) {
    return axios.post(API_URLA + 'getOverview/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz});
  }
  getGroupByParentBill(data_type, bills, date_start, date_end, tz, game_id) {
    return axios.post(API_URLA + 'getGroupByParentBill/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz, "game_id": game_id});
  }
  getReceipt(data_type, receipt_num) {
    return axios.post(API_URLA + 'getReceipt/', {"data_type": data_type, "receipt_id": receipt_num});
  }
  getTransactions(data_type, bills, date_start, date_end, tz, status) {
    return axios.post(API_URLA + 'getTransactions/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz, "type": status});
  }
  getTableTransactionsDetailed(data_type, bills, date_start, date_end, tz) {
    return axios.post(API_URLA + 'getTableTransactionsDetailed/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz});
  }
  getTableConsolidated(data_type, bills, date_start, date_end, tz) {
    return axios.post(API_URLA + 'getTableConsolidated/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz});
  }
  getTableWorkShift(data_type, bills, date_start, date_end, tz) {
    return axios.post(API_URLA + 'getTableWorkShift/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz});
  }
  getTableDetailedBets(data_type, bills, date_start, date_end, tz, status) {
    return axios.post(API_URLA + 'getTableDetailedBets/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz, "status": status});
  }
  getTableOverview(data_type, bills, date_start, date_end, tz) {
    return axios.post(API_URLA + 'getTableOverview/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz});
  }
  getJackpot(data_type, bills, date_start, date_end, tz, status) {
    return axios.post(API_URLA + 'getJackpot/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz});
  }
  getEventsDetailed(date_start, date_end, tz, isbets) {
    return axios.post(API_URLA + 'getEventsDetailed/', {"date_start": date_start, "date_end": date_end, "tz": tz, "isbets": isbets});
  }
  getPublicVirt(data_type, bills, date_start, date_end, tz, sel_bill, convert_to_usd) {
    return axios.post(API_URLA + 'getPublicVirt/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz, 'sel_bill': sel_bill, 'convert_to_usd': convert_to_usd});
  }
  getPublicTable(data_type, bills, date_start, date_end, tz, sel_bill, convert_to_usd) {
    return axios.post(API_URLA + 'getPublicTable/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz, 'sel_bill': sel_bill, 'convert_to_usd': convert_to_usd});
  }
  getVirtSurveyByGame(data_type, bills, date_start, date_end, tz) {
    return axios.post(API_URLA + 'getVirtSurveyByGame/', {"data_type": data_type, "bills": bills, "date_start": date_start, "date_end": date_end, "tz": tz});
  }
  getBillDelta(data_type, bills) {
    return axios.post(API_URLA + 'getBillDelta/', {"data_type": data_type, "bills": bills});
  }
}

export default new ReportService();
