import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'logotypes/';

class LogotypesService {
  saveLogotypes(formData) {
    return axios.post(API_URLA + 'saveLogotypes/',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  getLogotypes(id=null) {
    return axios.post(API_URLA + 'getLogotypes/', {id: id});
  }
  getLogotypesList() {
    return axios.post(API_URLA + 'getLogotypesList/');
  }
}

export default new LogotypesService();
