<template>
  <div class="bd-example" data-example-id="">
    <div class="form-group row" v-show="props.type=='addSub' || content.data_type=='demo' || content.show_data_type=='show'">
      <label class="col-md-2 col-form-label">{{ $t('bill.bill.demobill') }}</label>
      <div class="col-md-10">
        <el-checkbox v-model="content.demobills" type="checkbox" :disabled="content.data_type=='demo' && content.parent_data_type=='demo'" :readonly="content.data_type=='demo' && content.parent_data_type=='demo'"></el-checkbox>
      </div>
    </div>
    <div v-if="content.status=='active' || props.type=='addSub'">
      <div v-if="content.id"  class="form-group row">
        <label class="col-md-2 col-form-label">ID</label>
        <div class="col-md-10">
          <input class="form-control" disabled="disabled" type="text" v-model="content.id">
        </div>
      </div>
      <div class="form-group row">
        <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('bill.bill.accountName') }}</label>
        <div class="col-md-10">
          <input class="form-control" type="search" v-model="content.name" id="example-search-input">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('bill.bill.parent') }}</label>
        <div class="col-md-10">
          <input v-if="props.type=='addSub'" class="form-control" disabled="disabled" type="text" v-model="content.parent_title">
          <Select2 v-if="['edit', 'edit_save'].includes(props.type)" :disabled="!is_super && !is_admin_sb" v-model="content.parent" :options="parentsBills" :settings="{ width: '100%' }" />
        </div>
      </div>
      <div class="row m-3" v-if="content.parent!=parentCur && parentCur!='' && !showChangeParentBlock">
        <label class="col-md-2 col-form-label"></label>
        <div  class="col-10">
          <div v-if="typeb == '' || typeb == 'cancel'">
            <span class="alert-danger p-1">{{ $t('bill.bill.changeParentAlert')}}</span>
          </div>
          <div v-if="typeb == 'false'">
            <span class="alert-danger p-1">{{ $t('bill.bill.changeParentAlertWithoutSaveBalance')}} {{content.parent_title}}</span>
          </div>
          <div v-if="typeb == 'true'">
            <span class="alert-danger p-1">{{ $t('bill.bill.changeParentAlertWithSaveBalance')}}</span>
          </div>
          <div class="row">
            <div class="col-sm-6 p-2">
              <label class="m-2"><input @click="ChangeParent" type="radio" v-model="typeb" value="false" name="type"> {{ $t('bill.bill.balanceDontSave') }}</label>
              <label class="m-2"><input @click="ChangeParentSave" type="radio" v-model="typeb" value="true" name="type"> {{ $t('bill.bill.balanceSave') }}</label>
              <label class="m-2"><input @click="closeChangeParent" type="radio" v-model="typeb" value="cancel" name="type"> {{ $t('bill.bill.cancel') }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('bill.bill.comment') }}</label>
        <div class="col-md-10">
          <textarea class="form-control" type="text" v-model="content.comment"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('bill.bill.currency') }}</label>
        <div class="col-md-10">
          <Select2
              :disabled="!is_super || ['edit', 'edit_save'].includes(props.type) || content.parent!=1"
              v-model="content.currency"
              :options="currenciesOptions"
              :settings="{ width: '100%' }"
          />
        </div>
      </div>
      <div class="form-group row" v-if="!isnew">
        <label class="col-md-2 col-form-label">{{ $t('bill.bill.balance') }}</label>
        <div class="col-md-10">
          <input class="form-control" disabled="disabled" type="tel" v-model="content.balance">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('bill.bill.timezone') }}</label>
        <div class="col-md-10">
          <Select2 v-model="content.timezone" :options="tz_list" :settings="{ width: '100%' }" />
        </div>
      </div>
      <div class="form-group row" v-show="is_super || is_admin_sb">
        <label class="col-md-2 col-form-label">{{ $t('bill.bill.templateselect') }}</label>
        <div class="col-md-10">
          <Select2 v-model="content.template" :options="template_list" :settings="{ width: '100%' }" />
          <span v-if="validerr.get('template')" class="validerr">{{validerr.get('template')}}</span>
        </div>
      </div>
      <div class="form-group row" v-show="is_super || is_admin_sb">
        <label class="col-md-2 col-form-label">{{ $t('bill.bill.logotype') }}</label>
        <div class="col-md-10">
          <Select2 v-model="content.logotype" :options="logotype_list" :settings="{ width: '100%', allowClear: true }" />
        </div>
      </div>

      <Controls v-show="is_super || is_admin_sb" v-for="(item, key) in fields" :key="key" :type="item.type"
        v-model="content[item.code]"
        :label="item.label"
        :list="item.list"
        :code="item.code"
        :adddata="{valid: validerr.get(item.code)}"
      />

      <div class="container-fluid">
        <hr>
        <div class="form-group row">
          <div class="col">
            <div class="row">
              <div class="col-3">
                <label>{{ $t('bill.bill.minimumBet') }}</label>
              </div>
              <div class="col-3">
                <input :disabled="!access_groups['bet_change_minmax']" class="form-control" type="number" v-model="content.bet_min">
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-3">
                <label>{{ $t('bill.bill.maximumBet') }}</label>
              </div>
              <div class="col-3">
                <input :disabled="!access_groups['bet_change_minmax']" class="form-control" type="number" v-model="content.bet_max">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <hr>
        <div class="form-group row">
          <div class="col">
            <label>{{ $t('bill.bill.button1') }}</label>
            <input :disabled="!access_groups['cashier_bet_but_val']" class="form-control" type="number" v-model="content.but1">
          </div>
          <div class="col">
            <label>{{ $t('bill.bill.button2') }}</label>
            <input :disabled="!access_groups['cashier_bet_but_val']" class="form-control" type="number" v-model="content.but2">
          </div>
          <div class="col">
            <label>{{ $t('bill.bill.button3') }}</label>
            <input :disabled="!access_groups['cashier_bet_but_val']" class="form-control" type="number" v-model="content.but3">
          </div>
          <div class="col">
            <label>{{ $t('bill.bill.button4') }}</label>
            <input :disabled="!access_groups['cashier_bet_but_val']" class="form-control" type="number" v-model="content.but4">
          </div>
          <div class="col">
            <label>{{ $t('bill.bill.button5') }}</label>
            <input :disabled="!access_groups['cashier_bet_but_val']" class="form-control" type="number" v-model="content.but5">
          </div>
          <div class="col">
            <label>{{ $t('bill.bill.button6') }}</label>
            <input :disabled="!access_groups['cashier_bet_but_val']" class="form-control" type="number" v-model="content.but6">
          </div>
        </div>
      </div>
      <hr>
      <div v-if="false" class="form-group row">
        <div class="col">
          <label class="col-form-label">{{ $t('bill.bill.enableExtraBets') }}<input type="checkbox" v-model="extrabets">
          </label>
        </div>
      </div>
      <div v-if="false && extrabets==true" class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('bill.bill.extraBetsType') }}</label>
        <div class="col-md-10">
          <Select2  v-model="content.extra_bet_type" :options="extrabetlist" :settings="{ width: '100%' }" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('bill.bill.hardlimit') }}</label>
        <div class="col-md-10">
          <input type="checkbox" v-model="hardlimit_default">{{ $t('app.public.default')}}
          <input v-if="hardlimit_default==false" class="form-control" type="number" min="0" v-model="content.hardlimit">
          <p v-if="hardlimit_default!=true && content.hardlimit">{{content.hardlimit}} $ ({{content.hardlimit/content.usd_rate}} {{content.currency}})</p>
          <p v-if="hardlimit_default==true">{{content.hard_limit_default}} $ ({{content.hard_limit_default/content.usd_rate}} {{content.currency}})</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div v-if="['addSub', 'edit'].includes(props.type)" class="col-md-2">
      <button v-if="content.status=='active' || props.type=='addSub'" @click="rowSave" type="button" class="btn btn-success" :title="$t('bill.bill.ok')">Ok</button>
      <button @click="closeEdit" type="button" class="btn btn-danger" :title="$t('bill.bill.cancel')">{{ $t('bill.bill.cancel') }}</button>
    </div>
    <div v-if="props.type=='edit_save'" class="col-md-2">
      <button v-if="content.status=='active'" @click="rowSave" type="button" class="btn btn-success" :title="$t('app.public.save')">{{$t('app.public.save')}}</button>
    </div>

    <div v-if="['edit', 'edit_save'].includes(props.type) && access_groups['bill_sub_block']" class="col-md-10">
      <button v-if="content.status=='active'" @click="rowSetActiveStatus('blocked')" type="button" class="btn btn-secondary" :title="$t('bill.bill.block')">{{ $t('bill.bill.block') }}</button>
      <button v-if="content.status=='blocked'" @click="editBill('unblock')" type="button" class="btn btn-primary" :title="$t('bill.bill.unlock')">{{ $t('bill.bill.unlock') }}</button>
    </div>
    <div v-if="is_bill">
      <bill v-if="type!='unblock'" @selRow="onSelBill" @onGetRows="updBills" :selRow="sel_bill" :type="type"></bill>
      <BillBlock v-if="type=='unblock'" @selRow="onSelBill" :selRow="sel_bill"></BillBlock>
    </div>
  </div>
</template>

<script setup>
import BillService from "../../services/bill.service";
import CurrencieService from "../../services/currencie.service";
import {ref, defineEmits, defineProps, inject, onMounted, computed, watch, watchEffect} from "vue";
import {useStore} from "vuex";
import ReceiptService from "../../services/receipt.service";
import LogotypesService from "../../services/logotypes.service";
import Controls from "@/components/Controls/Main.vue";
import BillBlock from "./BillBlock.vue";

const $t = inject('$t')

const store = useStore()

const set_result = inject('set_result')
const access_groups = inject('access_groups')

const props = defineProps({
  selRow: Object,
  type: String,
  next_type: String
});

const emit = defineEmits(['selRow', 'onGetRows', 'updBills'])

const tz_list = inject('tz_list')
const template_list = ref([])

const coef_type_list = [
  {id: 'decimal', text: 'Decimal Odds'},
  {id: 'american', text: 'American Odds'},
]

function fieldsCmp()
{
  let res = []

  res.push({type: 'el-input', code: 'allowed_rejects', label: $t('bill.bill.allowed_rejects')}),
  res.push({type: 'el-select', code: 'coef_type', label: $t('bill.bill.coef_type'), list: coef_type_list, clear: true})

  return res
}
const fields = computed(fieldsCmp)

const validatas = inject('validatas')
const validerr = ref(new Map())

const verdata = ref(new Map([
    ['coef_type', {reg: '.+'}],
    ['template', {reg: '.+'}]
]));

const content = ref({'hardlimit': '', show_nearest_event: true, setting_partner: {}, coef_type: 'decimal'})
const myValue = ref('')
const currenciesOptions = ref([])
const parentsSel = ref('')
const parentsBills = ref([])
const parentCur = ref('')
const extrabets = ref(false)
const extrabetlist = ref([
  {id: 'classic', text: 'Classic'},
  {id: 'advanced', text: 'Advanced'}
])

const logotype_list = ref([])

const hardlimit_default = ref(true)

function closeEdit ()
{
  emit('selRow', {is_sel: false})
}

const settings = ref([])
const selSetting = ref([])

function verifyDataType()
{
  if(content.value.data_type=='demo' && content.value.demobills==false) {
    return confirm($t('bill.bill.towork'))
  }

  return true
}

function rowSave()
{
  let res_data_type = verifyDataType()

  if(res_data_type!=true) {
    return
  }

  let err_list=''
  if(content.value.name==undefined || content.value.name=='') {
    err_list=err_list+$t('bill.bill.accountName')+' '
  }

  if(content.value.timezone==undefined || content.value.timezone=='') {
    err_list=err_list+$t('bill.bill.timezone')+' '
  }

  if((content.value.hardlimit=='' || content.value.hardlimit==null) && hardlimit_default.value!=true) {
    err_list=err_list+$t('bill.bill.hardlimit')+' '
  }

  if(err_list!='') {
    err_list=$t('bill.bill.dataNotFound')+' '+err_list
    set_result('406', null, err_list)
    return
  }

  if(extrabets.value==false) {
    content.value.extra_bet_type=''
  }

  if (hardlimit_default.value===true) {
    content.value.hardlimit=''
  }

  validerr.value = validatas(content.value, verdata.value)

  if(validerr.value.size>0) {
    return
  }

  selSetting.value=[ "bill_sub_block", "user_create", "user_block", "user_change_pass", "cashier_bet_but_val" ]
  for (let cur in settings.value) {
    if (settings.value[cur].code == 'bill_enable_manual_bet_sum' && settings.value[cur].is_set == 1) {
      selSetting.value=[ "bill_sub_block", "user_create", "user_block", "user_change_pass", "cashier_bet_but_val", "bill_enable_manual_bet_sum" ]
    }
  }

  content.value.setting_partner = selSetting.value

  if (typeb.value == 'cancel' || typeb.value == '') {
    typeb.value = 'false'
  }
  content.value.save_balance = typeb.value

  BillService.saveBill(content.value).then(
    () => {
      let param={is_edit: false}

      if(props.next_type=='edit_save') {
        param['type']=props.next_type
        param['sel_bill']=props.selRow
      }

      emit('onGetRows')
      emit('selRow', param)
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function rowSetActiveStatus(status)
{
  BillService.setActiveStatus(props.selRow.id, status).then(
      () => {
        getBill()
        updBills()
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getBill()
{
  BillService.getBill(props.selRow.id).then(
    (response) => {
      content.value = response.data;

      content.value.demobills = false
      if(content.value.data_type=='demo') {
        content.value.demobills = true
      }

      if(content.value.extra_bet_type!=null) {
        extrabets.value=true
      }

      if (content.value.hardlimit!=null) {
        hardlimit_default.value=false
      }

      parentsSel.value=content.value.parent
      parentCur.value = parentsSel.value

      getBillsParentSel()
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function getBillData()
{
  if (['edit', 'edit_save'].includes(props.type)) {
    getBill()
  }

  if (props.type=='addSub') {
    content.value.id=''
    content.value.parent=props.selRow.id
    content.value.parent_title=props.selRow.bill_name+' ('+props.selRow.id+')'
    content.value.currency=props.selRow.currency
    myValue.value=content.value.currency;
    content.value.timezone=props.selRow.timezone_utc
    content.value.hardlimit=props.selRow.hardlimit
    content.value.allowed_rejects=10
    content.value.data_type=props.selRow.data_type
    content.value.parent_data_type=props.selRow.data_type
    if(props.selRow.parent_data_type!=undefined) {
      content.value.parent_data_type=props.selRow.parent_data_type
    }

    content.value.demobills = false
    if(props.selRow.data_type=='demo') {
      content.value.demobills = true
    }
  }
}

function getCurency()
{
  CurrencieService.getCurrenciesList().then(
    (response) => {
      currenciesOptions.value = response.data;
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function getSettByCurency()
{
  if(isnew.value) {
    BillService.getSettByCurency(content.value.currency).then(
        (response) => {
          content.value.bet_min=response.data.bet_min
          content.value.bet_max=response.data.bet_max

          content.value.but1=response.data.but1
          content.value.but2=response.data.but2
          content.value.but3=response.data.but3
          content.value.but4=response.data.but4
          content.value.but5=response.data.but5
          content.value.but6=response.data.but6
          content.value.usd_rate=response.data.usd_rate
          content.value.hard_limit_default=response.data.hard_limit_default
        },
        (error) => {
          set_result(error.response.status, error)
        }
    )
  }
}

function getBillsParentSel()
{
  BillService.getBillsParentSel(content.value.id).then(
    (response) => {
      parentsBills.value = response.data;
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function selCurrencyFn()
{
  return content.value.currency
}
const selCurrency = computed(selCurrencyFn)

function selCurrencyWatch()
{
  getSettByCurency()
}
watch(selCurrency, selCurrencyWatch)

function isnewFn()
{
  if (props.type=='addSub') {
    return true
  }
  return false
}
const isnew = computed(isnewFn)

function currentUserFn()
{
  return store.state.auth.user;
}
const currentUser = computed(currentUserFn)

function is_superFn()
{
  let res_acces=false;
  if (currentUser.value && currentUser.value['roles']) {

    if(currentUser.value['roles'].includes('super')) {
      res_acces=true
    }
  }

  return res_acces;
}
const is_super = computed(is_superFn)
const is_admin_sb = inject('is_admin_sb')

if(is_super.value==true || is_admin_sb.value==true) {
  verdata.value.set('allowed_rejects', {reg: '[0-9]*', min: 0})
}

function getTemplates()
{
  let bill_id=null

  if(content.value.id!=undefined && content.value.id!='') {
    bill_id=content.value.id
  } else {
    if(content.value.parent!=undefined && content.value.parent!='') {
      bill_id=content.value.parent
    }
  }

  if(bill_id!=null) {
    ReceiptService.getTemplatesList(bill_id).then(
        (response) => {
          template_list.value = JSON.parse(response.data)

          for(let tls in template_list.value) {
            if(template_list.value[tls].is_set=='1') {
              content.value.template=template_list.value[tls].id
            }
          }
        },
        (error) => {
          set_result(error.response.status, error)
        }
    )
  }
}
watchEffect(getTemplates)

function getLogotypes()
{
  LogotypesService.getLogotypesList().then(
      (response) => {
        logotype_list.value = response.data
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getBillSett()
{
    BillService.getSettings(props.selRow.id).then(
        (response) => {
          settings.value = response.data.settings;
        },
        (error) => {
          set_result(error.response.status, error)
        }
    )
}

const showChangeParentBlock = ref(false);
const typeb = ref('')

function ChangeParent()
{
  typeb.value = 'false'
  showChangeParentBlock.value = false;
}

function ChangeParentSave()
{
  typeb.value = 'true'
  showChangeParentBlock.value = false;
}

function closeChangeParent()
{
  typeb.value = ''
  content.value.parent = parentCur.value;
  showChangeParentBlock.value = false;
}

function verifyBlock(id)
{
  BillService.verifyBlock(id).then(
      () => {
        emit('selRow', {is_sel: true, sel_bill: props.selRow, type: 'unblock'})
      },
      (error) => {
        let nam_err=''
        let err_data=error.response.data.err_info
        if(err_data.indexOf('###upstream_blocking###')!=-1) {
          //let result = err_data.match(/[#][#][#](.*)[#][#][#]/);
          nam_err=$t('bill.billactions.upstream_blocking')
        }

        set_result(error.response.status, error, nam_err)
      }
  )
}

function editBill(type)
{
  if(type=='unblock') {
    verifyBlock(props.selRow.id)
    return
  }
  
  emit('selRow', {is_sel: true, sel_bill: props.selRow, type: type})
}

function getBillsTree ()
{
  store.dispatch('bill/updBills')
}

function updBills()
{
  getBillsTree()
  emit('updBills')
}

const is_bill = ref(false)
const sel_bill = ref({})
const type = ref('')

function onSelBill(params)
{
  sel_bill.value=params.sel_bill
  is_bill.value=params.is_sel
  type.value=params.type
}

function mounted_fn()
{
  getBillSett()
  getBillData()
  getCurency()
  // getTemplates()
  getLogotypes()
}

onMounted(mounted_fn)
</script>