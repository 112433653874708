<template>
  <div class="form-group row">
    <label v-if="props.label" class="col-md-2 col-form-label">{{props.label}}</label>
    <div class="col-md-10">
      <p v-if="props.type=='text'">{{data}}</p>

      <el-input v-if="props.type=='el-input'" v-model="data"></el-input>

      <el-select  v-if="props.type=='el-select'" v-model="data" filterable clearable placeholder="Select">
        <el-option
            v-for="item in list"
            :key="item.id"
            :label="item.text"
            :value="item.id"
        />
      </el-select>

      <div v-if="props.type=='password'" class="form-group row">
        <div class="col-10">
          <el-input v-model="data"></el-input>
        </div>
        <div class="col-2">
          <button @click="genPassword()">{{ $t('user.user.generatePassword') }}</button>
        </div>
      </div>

      <span v-if="props.adddata.valid" class="validerr">{{props.adddata.valid}}</span>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, provide, onMounted, watch, onActivated, inject, watchEffect} from "vue";
//import ctrInput from "@/components/Controls/ctrInput.vue"

const data=ref(null)

const props = defineProps({
  modelValue: String,
  type: String,
  label: String,
  list: Array,
  valid: String,
  code: String,
  adddata: Object
});

const emit = defineEmits(['update:modelValue'])

function updateValue(value) {
  emit('update:modelValue', value)
}
watch(data,  updateValue)

function updateValue2(value) {
  data.value=props.modelValue
}
watchEffect(updateValue2)

//const list=ref([])
const valid=ref('')

const genPass = inject('genPass')

function genPassword()
{
  data.value=genPass()
}

onMounted(() => {
  if(props.adddata.valid!==undefined) {
    valid.value=props.adddata.valid
  }
})
</script>
