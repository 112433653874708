<template>
  <div class="container-fluid">
    <div class="row">
      <div v-for="filter in filters" :key="filter" class="col">
        <ReportsFiltersFilterName :type="filter.type"></ReportsFiltersFilterName>
      </div>
      <div class="col"></div>
    </div>
    <div class="row">
      <div v-for="filter in filters" :key="filter" class="col">
        <ReportsFiltersFilter :type="filter.type"></ReportsFiltersFilter>
      </div>
      <div class="col">
        <button @click="runReport" class="btn btn-success m-1"><span v-if="!is_run">{{ $t('reports.reportsoverview.build') }}</span><span v-else>{{ $t('reports.reportsoverview.update') }}</span></button>
        <button v-if="exportbut" @click="downloadFile" class="btn btn-primary m-1">{{ $t('reports.reportsdetailed.export') }}</button>
      </div>
    </div>
  </div>
  <div v-if="helpers" class="row">
    <div class="col">
      <ReportsHelpers/>
    </div>
  </div>

  <p v-if="demomess!=''" class="demomess">{{demomess}}</p>
</template>

<script setup>
import {inject, ref, defineProps, defineEmits, onMounted, provide} from "vue";
import ReportsHelpers from "@/components/Reports/Filters/ReportsHelpers.vue";
import ReportsFiltersFilterName from "@/components/Reports/Filters/filterName.vue";
import ReportsFiltersFilter from "@/components/Reports/Filters/filter.vue";
import {useStore} from "vuex";
import GameService from "../../../services/game.service";

const props = defineProps({
  helpers: Boolean,
  exportbut: Boolean
});

const $t = inject('$t')

const store = useStore()

const emit = defineEmits({
  "onRunReport": {},
  "onDownloadFile": {}
})

const langEl=inject('langEl')
const dataformatEl=inject('dataOnlyformatEl')

const filters = inject('filters')
const filterData = inject('filterData')

const is_super = inject('is_super')

const is_run = inject('is_run')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

const demomess=inject('demomess')

const verifyDemoBill=inject('verifyDemoBill')

const set_result = inject('set_result')

const currentUser = inject('currentUser')

function runReport()
{
  verifyDemoBill()

  if(runProcess.value) {
    runProcess.value=false
    emit('onRunReport')
  }
}

function downloadFile()
{
  emit('onDownloadFile')
}

const games = ref([])
provide('games', games)

function get_games()
{
  if(games.value.length==0) {
    let currency = currentUser.value.currency;
    let bill_id = currentUser.value.bill_id;

    if(bill_id==1) {
      currency = 'AAA_MULTY'
    }

    GameService.getGamesByCurr(currency).then(
      (response) => {
        games.value = response.data

        games.value.unshift({id: undefined, text: $t('app.public.all')})
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function mount_fn()
{
  get_games()
}
onMounted(mount_fn)
</script>

<style scoped>
.demomess {
  color: red;
  margin-top: 10px;
  padding: 10px;
}
</style>