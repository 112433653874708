<template>
  <el-config-provider v-if="props.type=='datestart'" :locale="langEl">
    <el-date-picker
        v-model="filterData.ds"
        type="datetime"
        :format="dataformatEl"
    />
  </el-config-provider>

  <el-config-provider v-if="props.type=='dateend'" :locale="langEl">
    <el-date-picker
        v-model="filterData.de"
        type="datetime"
        :format="dataformatEl"
    />
  </el-config-provider>

  <el-select v-if="props.type=='timezone'" v-model="filterData.tz" filterable placeholder="Select">
    <el-option
        v-for="item in tz_list"
        :key="item.id"
        :label="item.text"
        :value="item.id"
    />
  </el-select>

  <el-select v-if="props.type=='status'" v-model="filterData.status" filterable placeholder="Select">
    <el-option
        v-for="item in check_res"
        :key="item.id"
        :label="item.text"
        :value="item.id"
    />
  </el-select>

  <el-select v-if="props.type=='game'" v-model="filterData.game" filterable placeholder="Select">
    <el-option
        v-for="item in games"
        :key="item.id"
        :label="item.text"
        :value="item.id"
    />
  </el-select>

  <el-checkbox
      id="idu_convert_to_usd"
      v-if="props.type=='convert_to_usd'"
      v-model="filterData.convert_to_usd"
      :label="$t('reports.filter.convertToUSD')"
  />

  <el-checkbox v-if="props.type=='isbets'" v-model="filterData.isbets" :label="$t('reports.reportseventsdetailed.isbets')"></el-checkbox>

  <input v-if="props.type=='receipt_id'" v-model="filterData.chn" />
</template>

<script setup>
import {defineProps, inject} from "vue";

const props = defineProps({
  type: String,
});

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const filterData = inject('filterData')

const tz_list = inject('tz_list')
const check_res = inject('check_res')
const games = inject('games')
</script>

<style scoped>

</style>