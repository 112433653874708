import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'receipt/';

class ReceiptService {
  getReceiptPrint(id) {
    return axios.post(API_URLA + 'getReceiptPrint/', {"id": id});
  }
  getTemplatePrint(template_id, view_full) {
    return axios.post(API_URLA + 'getTemplatePrint/', {id: template_id, view_full: view_full});
  }
  receiptCancel(id, code)
  {
    return axios.post(API_URLA + 'receiptCancel/', {id: id, code: code});
  }
  receiptPayoutByAdmin(id)
  {
    return axios.post(API_URLA + 'receiptPayoutByAdmin/', {id: id});
  }
  getReceiptTemplates()
  {
    return axios.post(API_URLA + 'getReceiptTemplates/');
  }
  copyTemplate(id)
  {
    return axios.post(API_URLA + 'copyTemplate/', id);
  }
  saveTemplate(template)
  {
    return axios.post(API_URLA + 'saveTemplate/', template);
  }
  delTemplate(id)
  {
    return axios.post(API_URLA + 'delTemplate/', id);
  }
  getTemplatesList(bill_id)
  {
    return axios.post(API_URLA + 'getTemplatesList/', {bill_id: bill_id});
  }
}

export default new ReceiptService();
