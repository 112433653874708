<template>
  <span v-if="props.type=='datestart'">{{ $t('reports.reportsdetailed.beginningOfPeriod') }}</span>

  <span v-if="props.type=='dateend'">{{ $t('reports.reportsdetailed.endOfPeriod') }}</span>

  <span v-if="props.type=='timezone'">{{ $t('reports.reportsdetailed.timezone') }}</span>

  <span v-if="props.type=='status'">{{ $t('reports.reportsdetailed.checks') }}</span>

  <span v-if="props.type=='game'">{{ $t('reports.filter.game') }}</span>

  <span v-if="props.type=='convert_to_usd'"></span>

  <span v-if="props.type=='isbets'"></span>

  <span v-if="props.type=='receipt_id'">{{ $t('reports.reportsviewreceipt.checkNumber') }}</span>
</template>

<script setup>
import {defineProps, inject} from "vue";

const props = defineProps({
  modelValue: String,
  type: String,
});

const langEl=inject('langEl')
const dataformatEl=inject('dataOnlyformatEl')

const filterData = inject('filterData')
</script>

<style scoped>

</style>